
export const ActionTypes = {
  // INIT STORE
  INIT_STORE: 'INIT_STORE',

  // TOPIC OPTIMAL INFO
  RECEIVE_TOPIC_OPTIMAL_DATA: 'RECEIVE_TOPIC_OPTIMAL_DATA',

  // ACTIVITIES
  RECEIVE_ACTIVITY_DATA: 'RECEIVE_ACTIVITY_DATA',
  NO_ACTIVITY_FOUND: 'NO_ACTIVITY_FOUND',

  // SESSION
  SESSION_HAS_NO_DATA: 'SESSION_HAS_NO_DATA',
  SET_ACTIVITY_SESSION_ID: 'SET_ACTIVITY_SESSION_ID',
  SET_SUBMITTED_RESPONSES: 'SET_SUBMITTED_RESPONSES',
  RECORD_FEEDBACK: 'RECORD_FEEDBACK',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_EXPLANATIONS_SLIDES_COMPLETED: 'SET_EXPLANATIONS_SLIDES_COMPLETED',
  SET_ACTIVITY_IS_COMPLETE_FOR_SESSION: 'SET_ACTIVITY_IS_COMPLETE_FOR_SESSION',
  SET_PREVIEW_SESSION_STEP: 'SET_PREVIEW_SESSION_STEP'
};
//
